import Vue from 'vue'
import Vuex from 'vuex'

import userModule from "./modules/user";
import loadingModule from './modules/loading'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user: userModule,
		loading: loadingModule,
	}
})
