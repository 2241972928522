<template>
    <CContainer class="shadow-lg p-3 mb-5 bg-light-subtle border-start-secondary rounded border align-self-center">
        <CRow>
            <CCol class="text-center">
                <h1>Coming Soon...</h1>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
import {CCol, CContainer, CRow} from "@coreui/vue/dist/esm/components/grid";
import {CCard, CCardBody, CCardHeader} from "@coreui/vue/dist/esm/components/card";

export default {
    name: "home-view",
    components: {CCard, CCardHeader, CCardBody, CContainer, CRow, CCol}
}
</script>

<style scoped>

</style>
