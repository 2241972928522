<template>
    <nav class="container-fluid navbar navbar-expand-lg navbar-dark bg-dark py-1 mx-auto">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">
                <img src="../assets/logo.svg" alt="logo" width="150" height="40"/>
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link active">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link v-if="Authenticated" to="/products" class="nav-link">Products</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link v-if="Authenticated" to="/shipto" class="nav-link">Ship To's</router-link>
                    </li>
                    <li v-if="Authenticated" class="nav-item dropdown bg-dark">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Setup
                        </a>

                        <ul class="dropdown-menu bg-dark">
                            <li><router-link to="/categories" class="dropdown-item text-white">Sub Categories</router-link></li>
                            <li><router-link to="/tags" class="dropdown-item text-white">Tag Keywords</router-link></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <router-link v-if="Authenticated" class="nav-link link-opacity-25-hover p-3" @click="onLogoutClick" :to="{ name: 'login' }">Logout</router-link>
        <router-link v-else class="nav-link link-opacity-25-hover p-3" :to="{ name: 'login' }">Login</router-link>
    </nav>
</template>

<script>

export default {
    name: 'nav-bar',

    components: {

    },

    methods: {
        onLogoutClick() {
            this.$store.dispatch('user/logout').then(() => {
                this.$gates.setRoles([])
                this.$root.$emit('user-logout')
                this.$router.push('/login')
            })
        }
    },

    computed: {
        Authenticated() {
            return this.$store.getters['user/authenticated']
        },

        User() {
            return this.$store.getters['user/user']
        },
    }
}
</script>

<style>

a.nav-link {
    color: #ffffff;
}

.nav-link:hover{
    color: rgb(108 179 63) !important;
}

a.router-link-exact-active {
    font-weight: bold;
    color: rgb(108, 180, 62) !important;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-image:none !important;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    background-color: rgb(108 179 63) !important;
}
</style>
