<template>
    <footer class="footer fixed-bottom bg-dark">
        <div class="container-fluid text-left">
            <span class="text-white">
                <img src="../assets/bcs.png" alt="" height="40" width="40"/> Powered By: <a href="https://bcsgroup.net/">Business Computer Solutions</a>
            </span>
        </div>
    </footer>
</template>

<script>
export default {
    name: "footer"
}
</script>

<style scoped>
a {
    font-weight: bold;
    color: rgb(108, 180, 62) !important;
}
</style>
