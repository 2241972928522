import ProductLine from "@/models/ProductLine";

export default class Item {
	constructor(options) {
		const defaults = {
			ItemCode: null,
			ItemType: null,
			ItemCodeDesc: null,
			SalesUnitOfMeasure: null,
			PurchaseUnitOfMeasure: null,
			StandardUnitOfMeasure: null,
			PurchaseUMConvFctr: null,
			SalesUMConvFctr: null,
			ProductType: null,
			ProductLine: null,
			ImageFile: null,
			Valuation: null,
			Category1: null,
			Category2: null,
			Category3: null,
			Category4: null,
			UDF_Web_Specification: null,
			UDF_Manufacturer: null,
			UDF_MFGPartNo: null,
			UDF_MfgProductLink: null,
			UDF_COO: null,
			UDF_UNSPSC: null,
			UDF_UPC: null,
			UDF_HTS: null,
			UDF_ProdLineCategory: null,
			MASUpdated: null,
			ImageUrl: null,
			HasImage: false,
			ExtendedDescriptionText: null,
			
			Product: null,
			Tags: [],
			SubCategories: []
		}
		
		this.ItemCode = options.ItemCode || defaults.ItemCode
		this.ItemType = options.ItemType || defaults.ItemType
		this.ItemCodeDesc = options.ItemCodeDesc || defaults.ItemCodeDesc
		this.SalesUnitOfMeasure = options.SalesUnitOfMeasure || defaults.SalesUnitOfMeasure
		this.PurchaseUnitOfMeasure = options.PurchaseUnitOfMeasure || defaults.PurchaseUnitOfMeasure
		this.StandardUnitOfMeasure = options.StandardUnitOfMeasure || defaults.StandardUnitOfMeasure
		this.PurchaseUMConvFctr = options.PurchaseUMConvFctr || defaults.PurchaseUMConvFctr
		this.SalesUMConvFctr = options.SalesUMConvFctr || defaults.SalesUMConvFctr
		this.ProductType = options.ProductType || defaults.ProductType
		this.ProductLine = options.ProductLine || defaults.ProductLine
		this.ImageFile = options.ImageFile || defaults.ImageFile
		this.Valuation = options.Valuation || defaults.Valuation
		this.Category1 = options.Category1 || defaults.Category1
		this.Category2 = options.Category2 || defaults.Category2
		this.Category3 = options.Category3 || defaults.Category3
		this.Category4 = options.Category4 || defaults.Category4
		this.UDF_Web_Specification = options.UDF_Web_Specification || defaults.UDF_Web_Specification
		this.UDF_Manufacturer = options.UDF_Manufacturer || defaults.UDF_Manufacturer
		this.UDF_MFGPartNo = options.UDF_MFGPartNo || defaults.UDF_MFGPartNo
		this.UDF_MfgProductLink = options.UDF_MfgProductLink || defaults.UDF_MfgProductLink
		this.UDF_COO = options.UDF_COO || defaults.UDF_COO
		this.UDF_UNSPSC = options.UDF_UNSPSC || defaults.UDF_UNSPSC
		this.UDF_UPC = options.UDF_UPC || defaults.UDF_UPC
		this.UDF_HTS = options.UDF_HTS || defaults.UDF_HTS
		this.UDF_ProdLineCategory = options.UDF_ProdLineCategory ? options.UDF_ProdLineCategory : defaults.UDF_ProdLineCategory
		this.MASUpdated = options.MASUpdated || defaults.MASUpdated
		this.ImageUrl = options.ImageUrl || defaults.ImageUrl
		this.HasImage = options.HasImage || defaults.HasImage
		this.ExtendedDescriptionText = options.ExtendedDescriptionText || defaults.ExtendedDescriptionText
		
		this.Product = options.Product ? new ProductLine(options.Product) : defaults.Product
		this.Tags = options.Tags ? options.Tags : defaults.Tags
		this.SubCategories = options.SubCategories ? options.SubCategories : defaults.SubCategories
	}
}
