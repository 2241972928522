export default class Warehouse {
	constructor(options) {
		const defaults = {
			WarehouseCode: null,
			WarehouseDesc: null,
			WarehouseName: null,
			WarehouseAddress1: null,
			WarehouseAddress2: null,
			WarehouseAddress3: null,
			WarehouseCity: null,
			WarehouseState: null,
		}
		
		this.WarehouseCode = options.WarehouseCode || defaults.WarehouseCode
		this.WarehouseDesc = options.WarehouseDesc || defaults.WarehouseDesc
		this.WarehouseName = options.WarehouseName || defaults.WarehouseName
		this.WarehouseAddress1 = options.WarehouseAddress1 || defaults.WarehouseAddress1
		this.WarehouseAddress2 = options.WarehouseAddress2 || defaults.WarehouseAddress2
		this.WarehouseAddress3 = options.WarehouseAddress3 || defaults.WarehouseAddress3
		this.WarehouseCity = options.WarehouseCity || defaults.WarehouseCity
		this.WarehouseState = options.WarehouseState || defaults.WarehouseState
	}
}
