import axios from 'axios';
import store from '@/stores/store'
import router from "@/router";

export const API = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	responseType: "json",
	withCredentials: false,
	
	headers: {
		Accept: 'application/json',
		Authorization: `Bearer ${store.getters['user/token']}`,
	},
})

API.interceptors.request.use((config) => {
	return config;
}, (error) => {
	return Promise.reject(error);
})

API.interceptors.response.use((config) => {
	return config
}, (error) => {

	return Promise.reject(error);
})

API.interceptors.response.use((config) => {
	store.dispatch('loading/end')
	return config
}, (error) => {
	store.dispatch('loading/end')
	
	switch (error.response.status) {
		case 401:
			store.commit('user/logout');
			router.push('/login');
			break;
		
		case 503:
			store.commit('user/logout');
			router.push('/login');
			break;
		
		default:
			store.dispatch('loading/end')
			break;
	}
	
	return Promise.reject(error);
})
