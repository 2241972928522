import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import "bootstrap"

import { createApp } from 'vue'
import App from './App.vue'

import ToastPlugin from 'vue-toast-notification';

import config from 'devextreme/core/config';
import { licenseKey } from '../devextreme-license';

import router from './router'
import store from './stores/store'

import VueGates from 'vue-gates';

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";

library.add(fas)

config({ licenseKey });

const app = createApp(App)

app.config.productionTip = false

app.use(VueGates, { superRole: 'Administrator', persistent: false });
app.use(router)
app.use(store)
app.use(ToastPlugin, { duration: 5000 })
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')



