export default class ProductLine {
	constructor(options) {
		const defaults = {
			ProductLine: null,
			ProductLineDesc: null,
			SubCategories: []
		}
		
		this.ProductLine = options.ProductLine || defaults.ProductLine
		this.ProductLineDesc = options.ProductLineDesc || defaults.ProductLineDesc
		this.SubCategories = options.SubCategories || defaults.SubCategories
	}
}
