export default class Tag {
	constructor(options) {
		const defaults = {
			ID: null,
			Name: null,
		}
		
		this.ID = options.ID || defaults.ID
		this.Name = options.Name || defaults.Name
	}
}
