<template>
    <CContainer class="align-self-center">
        <CRow class="justify-content-center">
            <CCol :xs="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 12, offset: 0 }" :xl="{ span: 10, offset: 0 }" class="shadow p-3 mb-5 bg-light-subtle border-start-secondary rounded border ">
                <CCard>
                    <CCardHeader class="text-center h5">Products Information Management</CCardHeader>
                    <CCardBody>
                        <CContainer class="px-1">
                            <CRow>
                                <CCol lg="auto" md="auto" sm="auto" xl="auto">
                                    <DxDataGrid
                                            ref="ItemsGridRef"
                                            :column-auto-width="true"
                                            :column-hiding-enabled="false"
                                            :data-source="items"
                                            :hover-state-enabled="true"
                                            :show-borders="true"
                                            :show-row-lines="true"
                                            :row-alternation-enabled="true"
                                            :repaint-changes-only="false"
                                            no-data-text="Please Wait..."
                                            key-expr="ItemCode"
                                            @row-expanding="onRowExpanding"
                                    >
                                        <DxSelection mode="none"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxFilterRow :visible="true"/>
                                        <DxSorting show-sort-indexes/>
                                        <DxPager
                                                :visible="true"
                                                :allowed-page-sizes="[20, 30, 40]"
                                                display-mode="full"
                                                :show-page-size-selector="true"
                                                :show-info="true"
                                                :show-navigation-buttons="true"
                                        />
                                        <DxSearchPanel
                                                :visible="true"
                                                :highlight-case-sensitive="true"
                                        />

                                        <DxDataGridToolBar>
                                            <DxDataGridToolBarItem location="before" template="refresh-template"/>
                                        </DxDataGridToolBar>

                                        <DxColumn
                                                type="detailExpand"
                                        />

                                        <DxColumn
                                                caption="Item Code"
                                                data-field="ItemCode"
                                                sort-index="0"
                                                :width="120"
                                                :header-filter="{width: 250, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                        <DxColumn
                                                caption="Description"
                                                data-field="ItemCodeDesc"
                                                :width="300"
                                                :header-filter="{width: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                        <DxColumn
                                                caption="Product Line"
                                                data-field="ProductLine"
                                                :width="250"
                                                :header-filter="{width: 'auto', allowSearch: true, position: { my: 'center', at: 'center'} }"
                                                :filter-operations="['contains']"
                                                :selected-operation="['contains']"
                                                :calculate-display-value="calculateProductLine"
                                                :calculate-cell-value="calculateProductLine"
                                                :calculate-filter-expression="(filterValue, selectedFilterOperation) => { return [this.calculateProductLine, selectedFilterOperation, filterValue] }"
                                        />
                                        <DxColumn
                                                caption="File"
                                                data-field="ImageFile"
                                                cell-template="image-url-cell-template"
                                                :header-filter="{width: 'auto', allowSearch: true, position: { my: 'center', at: 'center'}, dataSource: function(data) { data.dataSource.postProcess = function(results){ results[0].text = 'No Image';  return results; } } }"
                                        />
                                        <DxColumn
                                                caption=""
                                                :width="50"
                                                cell-template="image-cell-template"
                                                :allow-filtering="false"
                                                alignment="center"
                                        />
                                        <DxMasterDetail
                                                :enabled="true"
                                                template="master-detail-template"
                                        />

                                        <template #image-url-cell-template="{ data }">
                                            <div>
                                                <div v-if="data.data.HasImage">{{ data.data.ImageFile }}</div>
                                                <div v-else><i>No Image Uploaded</i></div>
                                            </div>
                                        </template>

                                        <template #image-cell-template="{ data }">
                                            <div>
                                                <div v-if="data.data.HasImage"><a href="#" @click="popUp(data.data.ImageUrl, 500, 500)"><font-awesome-icon size="xl" icon="image"/></a></div>
                                            </div>
                                        </template>

                                        <template #refresh-template>
                                            <DxButton hint="Refresh Items" icon="refresh" @click="FetchItems"/>
                                        </template>

                                        <template #master-detail-template="{ data }">
                                            <CContainer fluid class="p-0">
                                                <CRow>
                                                    <CCol lg="auto" md="auto" sm="auto" xl="auto">
                                                        <DxToolbar class="pb-2">
                                                            <DxItem
                                                                    widget="dxButton"
                                                                    :options="saveButtonOptions"
                                                            >
                                                            </DxItem>
                                                        </DxToolbar>
                                                    </CCol>
                                                </CRow>

                                                <CRow>
                                                    <DxForm
                                                            :form-data="selectedItem"
                                                            :min-col-width="300"
                                                            label-location="top"
                                                            col-count="auto"
                                                    >
                                                        <DxTabbedItem :tab-panel-options="tabPanelOptions">
                                                            <DxTab title="Identification" :col-count="2">
                                                                <DxGroupItem :col-count="2">
                                                                    <DxSimpleItem data-field="ExtendedDescriptionText" :col-span="2" :label="{ text: 'Description' }" editor-type="dxTextArea" :editor-options="{ height: 150, maxLength: 4096 }"/>
                                                                    <DxSimpleItem data-field="UDF_ProdLineCategory" :col-span="1" :label="{ text: 'Sub-Category' }" editor-type="dxSelectBox" :editor-options="{ dataSource: selectedItem.SubCategories, displayExpr: 'SubCategory', valueExpr: 'ID', keyExpr: 'ID' }"/>
                                                                </DxGroupItem>
                                                                <DxGroupItem :col-count="3">
                                                                    <DxSimpleItem data-field="UDF_COO" :col-span="3" :label="{ text: 'COO' }" editor-type="dxSelectBox" :editor-options="countryEditorOptions"/>
                                                                    <DxSimpleItem data-field="UDF_UPC" :col-span="1" :label="{ text: 'UPC' }" :editor-options="{ maxLength: 14 }"/>
                                                                    <DxEmptyItem :col-span="2"/>
                                                                    <DxSimpleItem data-field="UDF_UNSPSC" :col-span="1" :label="{ text: 'UNSPSC' }" :editor-options="{ maxLength: 12}"/>
                                                                    <DxEmptyItem :col-span="2"/>
                                                                    <DxSimpleItem data-field="UDF_HTS" :col-span="1" :label="{ text: 'HTS' }" :editor-options="{ maxLength: 13 }"/>
                                                                    <DxEmptyItem :col-span="2"/>
                                                                </DxGroupItem>
                                                            </DxTab>

                                                            <DxTab title="Manufacturer" :col-count="4">
                                                                <DxSimpleItem data-field="UDF_Manufacturer" :col-span="2" :label="{ text: 'UDF Manufacturer' }" :editor-options="{ maxLength: 30, width: 225 }"/>
                                                                <DxEmptyItem :col-span="2"/>
                                                                <DxSimpleItem data-field="UDF_MFGPartNo" :col-span="2" :label="{ text: 'UDF Manufacturer Part Number' }" :editor-options="{ inputAttr: { autocomplete: 'asfljkas' }, maxLength: 30, width: 225 }"/>
                                                                <DxEmptyItem :col-span="2"/>
                                                                <DxSimpleItem data-field="UDF_MfgProductLink" :col-span="2" :label="{ text: 'UDF Mfg Product Link' }" editor-type="dxTextArea" :editor-options="{ height: 75, maxLength: 250 }"/>
                                                                <DxEmptyItem :col-span="2"/>
                                                            </DxTab>

                                                            <DxTab title="Specification">
                                                                <DxSimpleItem data-field="UDF_Web_Specification" :label="{ visible: false }" editor-type="dxHtmlEditor" :editor-options="specificationHtmlEditorOptions"/>
                                                            </DxTab>

                                                            <DxTab title="Image" template="image-tab-template"/>

                                                            <DxTab title="Documents">
                                                            </DxTab>

                                                            <DxTab title="Tags" :col-count="6">
                                                                <DxSimpleItem data-field="Tags"
                                                                              :col-span="2" editor-type="dxTagBox"
                                                                              :label="{ text: 'Tags' }"
                                                                              :editor-options="{
                                                                                  dataSource: tags, maxDisplayedTags: 6, showDropDownButton: true, showSelectionControls: true,
                                                                                  acceptCustomValue: false, applyValueMode: 'useButtons',
                                                                                  displayExpr: 'Name', keyExpr: 'ID', valueExpr: 'ID',
                                                                                  onSelectionChanged: onTagsSelectionChanged
                                                                              }"/>
                                                                <DxEmptyItem :col-span="4"/>
                                                            </DxTab>
                                                        </DxTabbedItem>

                                                        <template #image-tab-template>
                                                            <CContainer>
                                                                <CRow class="py-2">
                                                                    <CCol>
                                                                        <div class="mb-2">
                                                                            <b><u>Item Code</u>:</b>
                                                                            {{ data.data.ItemCode }}
                                                                        </div>
                                                                        <div class="mb-2">
                                                                            <b><u>Description</u>:</b>
                                                                            {{ data.data.ItemCodeDesc }}
                                                                        </div>
                                                                    </CCol>
                                                                </CRow>
                                                                <CRow class="justify-content-start">
                                                                    <CCol lg="auto" md="auto" sm="auto" xl="auto" class="align-self-start">
                                                                        <div class="widget-container">
                                                                            <div class="border rounded p-2">
                                                                                <div
                                                                                        id="dropzone-external"
                                                                                        :class="[isDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color']"
                                                                                        class="flex-box"
                                                                                >
                                                                                    <img
                                                                                            v-if="imageSource"
                                                                                            id="dropzone-image"
                                                                                            :height="300"
                                                                                            :src="imageSource"
                                                                                            :width="300"
                                                                                            alt=""
                                                                                    >
                                                                                    <div
                                                                                            v-if="textVisible"
                                                                                            id="dropzone-text"
                                                                                            class="flex-box"
                                                                                    >
                                                                                        <span class="text-dark pb-2 text-wrap">{{ imageText }}</span>
                                                                                    </div>
                                                                                    <DxProgressBar
                                                                                            id="upload-progress"
                                                                                            :max="100"
                                                                                            :min="0"
                                                                                            :show-status="true"
                                                                                            :value="progressValue"
                                                                                            :visible="progressVisible"
                                                                                            width="30%"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <DxFileUploader
                                                                                    id="file-uploader"
                                                                                    :allowed-file-extensions="allowedFileExtensions"
                                                                                    :multiple="false"
                                                                                    :upload-url="UploadUrl"
                                                                                    :visible="false"
                                                                                    dialog-trigger="#dropzone-external"
                                                                                    drop-zone="#dropzone-external"
                                                                                    name="file"
                                                                                    upload-mode="instantly"
                                                                                    @progress="onProgress"
                                                                                    @uploaded="onUploaded"
                                                                                    @drop-zone-enter="onDropZoneEnter"
                                                                                    @drop-zone-leave="onDropZoneLeave"
                                                                                    @upload-started="onUploadStarted"
                                                                            />
                                                                        </div>
                                                                    </CCol>

                                                                    <CCol lg="auto" md="auto" sm="auto" xl="auto" class="align-self-start">
                                                                        <div class="widget-container">
                                                                            <div class="border rounded p-2">
                                                                                <div class="flex-box border rounded p-0">
                                                                                    <CImage
                                                                                            id="dropzone-image"
                                                                                            :height="200"
                                                                                            :src="data.data.ImageUrl"
                                                                                            :width="200"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </CCol>
                                                                </CRow>
                                                            </CContainer>
                                                        </template>
                                                    </DxForm>
                                                </CRow>
                                            </CContainer>
                                        </template>
                                    </DxDataGrid>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <DxLoadPanel
                :visible="loading"
                :message="loadingText"
                :max-width="500"
                :min-width="250"
                indicator-src='./assets/rolling.svg'
                shading-color="rgba(0,0,0,0.4)"
        />
    </CContainer>
</template>

<script>
/* eslint-disable */

import DxToolbar, { DxItem } from 'devextreme-vue/toolbar';
import DxTagBox from 'devextreme-vue/tag-box';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxProgressBar } from 'devextreme-vue/progress-bar';
import { API } from '@/API'

import {
    CContainer,
    CRow,
    CCol,
    CCard,
    CCardHeader,
    CCardBody,
    CTabs,
    CTab,
    CTabList,
    CTabContent,
    CTabPanel,
    CImage
} from '@coreui/vue'

import {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    DxSelection,
    DxToolbar as DxDataGridToolBar,
    DxItem as DxDataGridToolBarItem,
    DxPaging,
    DxPager,
    DxHeaderFilter,
    DxSorting,
    DxFilterRow,
    DxSearchPanel,
} from 'devextreme-vue/data-grid';

import {
    DxHtmlEditor,
    DxToolbar as DxHtmlEditorToolBar,
    DxMediaResizing,
    DxItem as DxHtmlEditorToolBarItem,
} from 'devextreme-vue/html-editor';

import {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxEmptyItem,
    DxTabbedItem,
    DxTabPanelOptions,
    DxTab,
    DxGroupItem
} from 'devextreme-vue/form';

import 'devextreme-vue/text-area';
import 'devextreme-vue/tag-box';

import { DxButton } from 'devextreme-vue/button'
import { DxLoadPanel } from 'devextreme-vue/load-panel';

import Item from '@/models/Item'
import Tag from "@/models/Tag";

import { countries } from "@/constants";

const ImageText = 'Drag & Drop the desired file or click to browse for a file.'

export default {
    name: 'products-view',

    components: {
        DxDataGrid,
        DxColumn,
        DxSearchPanel,
        DxMasterDetail,
        DxHtmlEditor,
        DxHtmlEditorToolBar,
        DxHtmlEditorToolBarItem,
        DxMediaResizing,
        DxFileUploader,
        DxProgressBar,
        DxSelection,
        DxDataGridToolBar,
        DxDataGridToolBarItem,
        DxPaging,
        DxPager,
        DxHeaderFilter,
        DxSorting,
        DxFilterRow,
        DxButton,
        DxLoadPanel,

        CContainer,
        CRow,
        CCol,
        CCard,
        CCardHeader,
        CCardBody,
        CTabs,
        CTab,
        CTabList,
        CTabContent,
        CTabPanel,
        CImage,

        Image,

        DxForm,
        DxSimpleItem,
        DxButtonItem,
        DxLabel,
        DxEmptyItem,
        DxTabbedItem,
        DxTabPanelOptions,
        DxTab,
        DxGroupItem,

        DxTagBox,
        DxToolbar,
        DxItem
    },

    async created() {
        await Promise.all([
            this.FetchItems(),
            this.FetchTags(),
        ])
    },

    data() {
        return {
            items: [],
            subcategories: [],
            selectedItem: new Item({}),
            sizeValues: ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'],
            fontValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
            fontSizeOptions: {inputAttr: {'aria-label': 'Font size'}},
            fontFamilyOptions: {inputAttr: {'aria-label': 'Font family'}},
            isDropZoneActive: false,
            imageSource: '',
            textVisible: true,
            progressVisible: false,
            progressValue: 0,
            allowedFileExtensions: ['.jpg', '.jpeg', '.tiff', '.gif', '.bmp', '.png'],
            imageText: ImageText,
            loading: false,
            loadingText: 'Loading...',
            countryEditorOptions: {
                dataSource: this.countries(),
                displayExpr: function(country) { return country && country.countryCode + ' - ' + country.name },
                valueExpr: 'countryCode',
                keyExpr: 'countryCode',
                dropDownOptions: { maxHeight: 150, minWidth: 250 },
                searchExpr: 'name',
                searchMode: "startswith",
                searchEnabled: true,
                width: 375
            },
            tags: [],

            saveButtonOptions: {
                icon: 'save',
                text: 'Save',
                type: 'success',
                onClick: (e) => this.saveItem(e)
            },

            specificationHtmlEditorOptions: {
                valueType: 'html',
                placeholder:'Please type a specification...',
                width:'100%',
                height: 275,
                mediaResizing: {
                    enabled:true
                },
                toolbar: {
                    multiline: false,
                    items: [
                        "undo",
                        "redo",
                        "separator",
                        "bold",
                        "italic",
                        "strike",
                        "underline",
                        "separator",
                        "alignLeft",
                        "alignCenter",
                        "alignRight",
                        "alignJustify",
                        "separator",
                        "orderedList",
                        "bulletList",
                        "separator",
                        {
                            acceptedValues: [false, 1, 2, 3, 4, 5],
                            options: { inputAttr: {'aria-label': 'Font family' } },
                            name: "header"
                        },
                        "separator"
                    ]
                }
            },

            tabPanelOptions: {
                height: 350,
                deferRendering: false,
                selectedIndex: 0,

                onSelectionChanged: (e) => {
                    this.tabPanelOptions.selectedIndex = e.component.option('selectedIndex')
                }
            },
        }
    },

    methods: {
        countries() {
            return countries.sort((a, b) => a.countryCode.localeCompare(b.countryCode));
        },

        async FetchItems() {
            this.loading = true
            this.loadingText = "Loading Items..."

            API.get('/items')
                    .then(response => {
                        this.items = response.data.map(item => new Item(item))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        async FetchTags() {
            this.loading = true
            this.loadingText = "Loading Tags..."

            API.get('/tags')
                    .then(response => {
                        this.tags = response.data.map(tag => new Tag(tag))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        onDropZoneEnter(e) {
            if (e.dropZoneElement.id === 'dropzone-external') {
                this.isDropZoneActive = true;
            }
        },

        onDropZoneLeave(e) {
            if (e.dropZoneElement.id === 'dropzone-external') {
                this.isDropZoneActive = false;
            }
        },

        onUploaded(e) {
            this.$toast.success('Image Upload Successfully...');

            let response = JSON.parse(e.request.response)

            this.imageText = ImageText

            this.isDropZoneActive = false;
            this.textVisible = true;
            this.progressVisible = false;
            this.progressValue = 0;

            this.selectedItem = new Item(response)
            this.items = this.items.map((item) => { return item.ItemCode === this.selectedItem.ItemCode ? {...item, ...this.selectedItem} : item; });
        },

        onProgress(e) {
            this.progressValue = (e.bytesLoaded / e.bytesTotal) * 100;
        },

        onUploadStarted() {
            this.$toast.info('Image Upload Started...');

            this.imageText = 'Image is being uploaded...';
            this.progressVisible = true;
        },

        onRowExpanding(e) {
            e.component.collapseAll(-1);

            this.selectedItem = this.items.find(({ItemCode}) => ItemCode === e.key)
        },

        onTagsSelectionChanged(e) {
            const items = e.component.option('selectedItems')

            this.selectedItem.Tags = items.map(item => item.ID)
        },

        calculateProductLine(rowData) {
            return rowData.Product ? `${rowData.Product.ProductLine} - ${rowData.Product.ProductLineDesc}` : rowData.ProductLine
        },

        calculateProductLineFilterExpression(filterValue, selectedFilterOperation, target) {
            // Invoke the default implementation for other filter operations
            if(!this.defaultCalculateFilterExpression)
                return [this.dataField, 'contains', filterValue];
            return this.defaultCalculateFilterExpression.apply(this, arguments);
        },

        popUp(URL,w,h) {
            window.open(URL,"_pu"+(Math.random()+"").replace(/0\./,""),"toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width="+w+",height="+h+",left="+((window.screen.width-w)/2)+",top="+((window.screen.height-h)/2));
        },

        saveItem(e) {
            this.loading = true
            this.loadingText = `Saving Item ${this.selectedItem.ItemCode}...`



            API.post(`/items/${this.selectedItem.ItemCode}`, this.selectedItem)
                    .then(response => {
                        this.selectedItem = new Item(response.data)
                        this.items = this.items.map((item) => { return item.ItemCode === this.selectedItem.ItemCode ? {...item, ...this.selectedItem} : item; });

                        this.$toast.success(`Item: ${this.selectedItem.ItemCode} Changes Saved...`)
                    })
                    .catch(error => {
                        this.$toast.error(error.response.message);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },
    },

    computed: {
        UploadUrl() {
            return new URL(`/api/ecommerce/items/image/upload${this.selectedItem ? '/' + this.selectedItem.ItemCode : ''}`, process.env.VUE_APP_API_URL).toString()
        },

        ItemsGrid() {
            return this.$refs['ItemsGridRef'].instance
        }
    },
}
</script>

<style scoped>

.v-container {
    margin: auto;
    width: 65%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tab-content {
    display: flex;
    height: 300px;
}

.tab-content > .tab-pane {
    display: block; /* undo "display: none;" */
    visibility: hidden;
    margin-right: -100%;
    width: 100%;
}

.tab-content > .active {
    visibility: visible;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    font-weight: bold;
}

.dx-htmleditor-content img {
    vertical-align: middle;
    padding-right: 10px;
}

.dx-htmleditor-content table {
    width: 50%;
}

.option > span {
    margin-right: 10px;
}

#dropzone-external {
    width: 200px;
    height: 200px;
    background-color: white;
    color: black;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    text-align: center;
}

#dropzone-external > * {
    pointer-events: none;
}

#dropzone-external.dropzone-active {
    border-style: dashed;
    border-width: 0.5em;
    background-color: lightblue;
    padding: 0;
}

.widget-container > span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
}

#dropzone-image {
    max-width: 100%;
    max-height: 100%;
}

#dropzone-text > span {
    font-weight: bold;
    opacity: 0.75;
    color: #2c3e50 !important;
}

#upload-progress {
    display: flex;
    margin-top: 10px;
}

.flex-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell, .dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) > .dx-datagrid-group-space {
    background-color: #7d7d7d !important;
}

.dx-field-label {
    float: left;
}
.dx-field-value {
    float: left;
}
.dx-field-value-static {
    float: left;
}

:deep(ql-editor dx-htmleditor-content) {
    background-color: white !important;
}

:deep(.dx-datagrid-nodata) {
    color: #fe0000 !important;
    font-size: 24px !important;
}
</style>
