import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from './views/HomeView.vue'
import ProductsView from "@/views/ProductsView.vue";
import ShipToView from "@/views/ShipToView.vue";
import SubCategoriesView from "@/views/SubCategoriesView.vue";
import TagKeywordsView from "@/views/TagKeywordsView.vue";
import LoginView from "@/views/Auth/LoginView.vue";

import store from "@/stores/store";

const routes = [
	{
		path: '/',
		name: 'home',
		meta: {
			requiresAuth: false,
			requiredRoles: ['User'],
			requiredPermissions: []
		},
		
		component: HomeView
	},
	{
		path: '/products',
		name: 'products',
		meta: {
			requiresAuth: true,
			requiredRoles: ['User'],
			requiredPermissions: []
		},
		
		component: ProductsView
	},
	{
		path: '/shipto',
		name: 'shipto',
		meta: {
			requiresAuth: true,
			requiredRoles: ['User'],
			requiredPermissions: []
		},
		
		component: ShipToView
	},
	{
		path: '/categories',
		name: 'categories',
		meta: {
			requiresAuth: true,
			requiredRoles: ['User'],
			requiredPermissions: []
		},
		
		component: SubCategoriesView
	},
	{
		path: '/tags',
		name: 'tags',
		meta: {
			requiresAuth: true,
			requiredRoles: ['User'],
			requiredPermissions: []
		},
		
		component: TagKeywordsView
	},
	{
		path: "/login",
		name: "login",
		meta: {
			requiresAuth: false,
			requiredRoles: [],
			requiredPermissions: []
		},
		
		component: LoginView
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})

router.beforeResolve((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
	const authenticated = store.getters['user/authenticated']
	
	if (requiresAuth && !authenticated) {
		next('/login')
	} else if (to.path === '/login' && authenticated) {
		next('/home')
	} else {
		next();
	}
});

export default router
