<template>
    <CContainer class="align-self-center">
        <CRow class="justify-content-center">
            <CCol :xs="{ span: 12, offset: 0 }" :md="{ span: 6, offset: 0 }" :lg="{ span: 5, offset: 0 }" :xl="{ span: 4, offset: 0 }" class="shadow p-3 mb-5 bg-light-subtle border-start-secondary rounded border ">
                <CCard>
                    <CCardHeader class="text-center h5">Sub-Categories Management</CCardHeader>
                    <CCardBody>
                        <CContainer class="px-1">
                            <CRow>
                                <CCol lg="auto" md="auto" sm="auto" xl="auto">
                                    <DxDataGrid
                                            ref="ProductLinesDataGridRef"
                                            :data-source="products"
                                            :hover-state-enabled="true"
                                            :show-borders="true"
                                            :show-row-lines="true"
                                            :row-alternation-enabled="true"
                                            :repaint-changes-only="false"
                                            :show-column-headers="false"
                                            key-expr="ProductLine"
                                            height="auto"
                                            width="auto"
                                            no-data-text="No Sub-Categories..."
                                            @row-expanding="onRowExpanding"
                                    >
                                        <DxSelection mode="none"/>
                                        <DxPaging :enabled="true" :page-size="10"/>
                                        <DxFilterRow :visible="false"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxScrolling mode="virtual"/>

                                        <DxDataGridToolBar>
                                            <DxDataGridToolBarItem location="before" template="refresh-template"/>
                                        </DxDataGridToolBar>

                                        <DxColumn
                                                data-field="ProductLine"
                                                caption="Product Line"
                                                sort-index="0"
                                                width="auto"
                                                :calculate-cell-value="calculateProductLineCellValue"
                                                :header-filter="{width: 175, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />

                                        <DxMasterDetail
                                                :enabled="true"
                                                template="master-detail-template"
                                        />

                                        <template #master-detail-template="{ data }">
                                            <CContainer fluid class="p-0">
                                                <CRow>
                                                    <CCol lg="auto" md="auto" sm="auto" xl="auto">
                                                        <DxDataGrid
                                                                ref="SubCategoriesDataGridRef"
                                                                :data-source="data.data.SubCategories"
                                                                :hover-state-enabled="true"
                                                                :show-borders="true"
                                                                :show-row-lines="true"
                                                                :row-alternation-enabled="true"
                                                                :repaint-changes-only="false"
                                                                :show-column-headers="false"
                                                                key-expr="ID"
                                                                height="auto"
                                                                width="auto"
                                                                no-data-text="No Sub-Categories..."
                                                                @saved="onSaved"
                                                        >

                                                            <DxEditing
                                                                    :allow-updating="true"
                                                                    :allow-adding="true"
                                                                    :allow-deleting="true"
                                                                    mode="popup">

                                                                <DxPopup
                                                                        shading-color="rgba(0,0,0,0.4)"
                                                                        :width="275"
                                                                        height="auto"
                                                                >
                                                                </DxPopup>

                                                                <DxForm
                                                                        label-location="top"
                                                                        :col-count="1"
                                                                >

                                                                </DxForm>
                                                            </DxEditing>

                                                            <DxColumn
                                                                    data-field="SubCategory"
                                                            />


                                                        </DxDataGrid>
                                                    </CCol>
                                                </CRow>
                                            </CContainer>
                                        </template>

                                        <template #refresh-template>
                                            <DxButton hint="Refresh Items" icon="refresh" @click="FetchSubCategories"/>
                                        </template>
                                    </DxDataGrid>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>

    <DxLoadPanel
            :visible="loading"
            :message="loadingText"
            :max-width="500"
            :min-width="250"
            indicator-src='./assets/rolling.svg'
            shading-color="rgba(0,0,0,0.4)"
    />
</template>

<script>
import { CCol, CContainer, CRow } from "@coreui/vue/dist/esm/components/grid";
import { CCard, CCardBody, CCardHeader } from "@coreui/vue/dist/esm/components/card";
import { DxDropDownBox, DxDropDownOptions } from 'devextreme-vue/drop-down-box';
import { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxEditing, DxForm, DxLabel, DxPopup, DxHeaderFilter, DxSearchPanel, DxMasterDetail, DxToolbar as DxDataGridToolBar, DxItem as DxDataGridToolBarItem, } from 'devextreme-vue/data-grid';
import { DxItem, DxEmptyItem } from "devextreme-vue/form";
import { API } from "@/API";
import { DxLoadPanel } from 'devextreme-vue/load-panel';

import ProductLine from '@/models/ProductLine'
import DxButton from "devextreme-vue/button";

export default {
    name: "subcategories-view",

    components: {
        CCard, CCardHeader, CCardBody, CContainer, CRow, CCol,
        DxDropDownBox, DxDropDownOptions,
        DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxEditing, DxForm, DxLabel, DxPopup, DxHeaderFilter, DxSearchPanel, DxMasterDetail, DxDataGridToolBar, DxDataGridToolBarItem,
        DxItem, DxEmptyItem,
        DxButton,
        DxLoadPanel
    },

    async created() {
        await Promise.all([
            this.FetchSubCategories()
        ])
    },

    data() {
        return {
            selectedProductLine: new ProductLine({}),
            products: [],
            selected: null,
            loading: false,
            loadingText: '',

            popupOptions: {
                title: 'Info',
                showTitle: true,
                width: 'auto',
                height: 'auto'
            }
        }
    },

    methods: {
        async FetchSubCategories() {
            this.loading = true
            this.loadingText = "Loading Sub-Categories..."

            API.get('/subcategories')
                    .then(response => {
                        this.products = response.data.map(product => new ProductLine(product))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        onSaved(e) {
            this.loading = true

            if(e.changes.length) {
                switch (e.changes[0].type) {
                    case "remove":
                        this.loadingText = "Deleting Tag..."

                        API.delete(`/subcategories/${e.changes[0].key}`)
                                .catch(error => {
                                    this.$toast.error(error);
                                })
                                .finally(() => {
                                    this.loading = false
                                    this.loadingText = ""
                                })
                        break;

                    case "update":
                        this.loadingText = "Updating Sub-Category..."

                        API.put('/subcategories', e.changes[0].data)
                                .catch(error => {
                                    this.$toast.error(error);
                                })
                                .finally(() => {
                                    this.loading = false
                                    this.loadingText = ""
                                })
                        break;

                    case "insert":
                        this.loadingText = "Adding Sub-Category..."

                        let data = e.changes[0].data

                        data.ProductLineID = this.selectedProductLine.ProductLine

                        API.post(`/subcategories`, data)
                                .catch(error => {
                                    this.$toast.error(error);
                                })
                                .finally(() => {
                                    this.loading = false
                                    this.loadingText = ""

                                    this.FetchSubCategories()
                                })
                        break;
                }
            }
        },

        onRowExpanding(e) {
            e.component.collapseAll(-1);

            this.selectedProductLine = this.products.find(({ProductLine}) => ProductLine === e.key)
        },

        calculateProductLineCellValue(rowData) {
            return `${ rowData.ProductLine } - ${ rowData.ProductLineDesc }`
        }
    },

    computed: {
        SubCategoriesDataGrid() {
            return this.$refs['SubCategoriesDataGridRef'].instance
        },

        ProductLinesDataGrid() {
            return this.$refs['ProductLinesDataGridRef'].instance
        }
    }
}
</script>

<style scoped>

</style>
