<template>
    <CContainer class="align-self-center">
        <CRow class="justify-content-center">
            <CCol :xs="{ span: 12, offset: 0 }" :md="{ span: 6, offset: 0 }" :lg="{ span: 5, offset: 0 }" :xl="{ span: 4, offset: 0 }" class="shadow p-3 mb-5 bg-light-subtle border-start-secondary rounded border ">
                <CCard>
                    <CCardHeader class="text-center h5">Tag Management</CCardHeader>
                    <CCardBody>
                        <CContainer class="px-1">
                            <CRow>
                                <CCol lg="auto" md="auto" sm="auto" xl="auto">
                                    <DxDataGrid
                                            ref="TagsDataGridRef"
                                            :data-source="tags"
                                            :hover-state-enabled="true"
                                            :show-borders="true"
                                            :show-row-lines="true"
                                            :row-alternation-enabled="true"
                                            :repaint-changes-only="false"
                                            :show-column-headers="false"
                                            key-expr="ID"
                                            height="auto"
                                            width="auto"
                                            no-data-text="No Tags..."
                                            @saved="onSaved"
                                    >
                                        <DxSelection mode="none"/>
                                        <DxPaging :enabled="true" :page-size="10"/>
                                        <DxFilterRow :visible="false"/>
                                        <DxHeaderFilter :visible="true"/>
                                        <DxScrolling mode="virtual"/>

                                        <DxEditing
                                                :allow-updating="true"
                                                :allow-adding="true"
                                                :allow-deleting="true"
                                                mode="popup">

                                            <DxPopup
                                                    shading-color="rgba(0,0,0,0.4)"
                                                    :width="275"
                                                    height="auto"
                                            >
                                            </DxPopup>

                                            <DxForm
                                                    label-location="top"
                                                    :col-count="1"
                                            >

                                            </DxForm>
                                        </DxEditing>

                                        <DxColumn
                                                data-field="Name"
                                                caption="Tag Name"
                                                sort-order="asc"
                                                width="auto"
                                                :header-filter="{width: 175, allowSearch: true, position: { my: 'center', at: 'center'} }"
                                        />
                                    </DxDataGrid>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>

    <DxLoadPanel
            :visible="loading"
            :message="loadingText"
            :max-width="500"
            :min-width="250"
            indicator-src='./assets/rolling.svg'
            shading-color="rgba(0,0,0,0.4)"
    />
</template>

<script>
import { CCol, CContainer, CRow } from "@coreui/vue/dist/esm/components/grid";
import { CCard, CCardBody, CCardHeader } from "@coreui/vue/dist/esm/components/card";
import { DxDropDownBox, DxDropDownOptions } from 'devextreme-vue/drop-down-box';
import { DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxEditing, DxForm, DxLabel, DxPopup, DxHeaderFilter, DxSearchPanel, DxMasterDetail, DxToolbar as DxDataGridToolBar, DxItem as DxDataGridToolBarItem, } from 'devextreme-vue/data-grid';
import { DxItem, DxEmptyItem } from "devextreme-vue/form";
import { API } from "@/API";
import { DxLoadPanel } from 'devextreme-vue/load-panel';

import DxButton from "devextreme-vue/button";
import Tag from "@/models/Tag";

export default {
    name: "tags-keywords-view",

    components: {
        CCard, CCardHeader, CCardBody, CContainer, CRow, CCol,
        DxDropDownBox, DxDropDownOptions,
        DxDataGrid, DxColumn, DxSelection, DxPaging, DxFilterRow, DxScrolling, DxEditing, DxForm, DxLabel, DxPopup, DxHeaderFilter, DxSearchPanel, DxMasterDetail, DxDataGridToolBar, DxDataGridToolBarItem,
        DxItem, DxEmptyItem,
        DxButton,
        DxLoadPanel
    },

    async created() {
        await Promise.all([
            this.FetchTags()
        ])
    },

    data() {
        return {
            tags: [],
            loading: false,
            loadingText: null,
        }
    },

    methods: {
        async FetchTags() {
            this.loading = true
            this.loadingText = "Loading Tags..."

            API.get('/tags')
                    .then(response => {
                        this.tags = response.data.map(tag => new Tag(tag))
                    })
                    .catch(error => {
                        this.$toast.error(error);
                    })
                    .finally(() => {
                        this.loading = false
                        this.loadingText = ""
                    })
        },

        onSaved(e) {
            this.loading = true

            if(e.changes.length) {
                switch (e.changes[0].type) {
                    case "remove":
                        this.loadingText = "Deleting Tag..."

                        API.delete(`/tags/${e.changes[0].key}`)
                                .catch(error => {
                                    this.$toast.error(error);
                                })
                                .finally(() => {
                                    this.loading = false
                                    this.loadingText = ""
                                })
                        break;

                    case "update":
                        this.loadingText = "Updating Tag..."

                        API.put('/tags', e.changes[0].data)
                                .catch(error => {
                                    this.$toast.error(error);
                                })
                                .finally(() => {
                                    this.loading = false
                                    this.loadingText = ""
                                })
                        break;

                    case "insert":
                        this.loadingText = "Adding Tag..."

                        API.post(`/tags`, e.changes[0].data)
                                .catch(error => {
                                    this.$toast.error(error);
                                })
                                .finally(() => {
                                    this.loading = false
                                    this.loadingText = ""

                                    this.FetchTags()
                                })
                        break;
                }
            }
        },
    },

    computed: {
        TagsDataGrid() {
            this.$refs['TagsDataGridRef'].instance
        }
    }
}
</script>

<style scoped>

</style>
