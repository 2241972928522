<template>
    <div id="app">
        <NavBar/>
        <main class="pt-5">
            <router-view/>
        </main>
        <Footer/>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
    name: 'App',
    components: {
        Footer,
        NavBar
    },
});
</script>

<style>
    body {
        background-color: #ffffff;
    }
</style>
