export default class Customer {
	constructor(options) {
		const defaults = {
			PrimaryKey: null,
			ARDivisionNo: null,
			CustomerNo: null,
			CustomerName: null,
			City: null,
			State: null,
			ZipCode: null,
			CustomerStatus: null,
		}
		
		this.PrimaryKey = options.PrimaryKey || defaults.PrimaryKey
		this.ARDivisionNo = options.ARDivisionNo || defaults.ARDivisionNo
		this.CustomerNo = options.CustomerNo || defaults.CustomerNo
		this.CustomerName = options.CustomerName || defaults.CustomerName
		this.City = options.City || defaults.City
		this.State = options.State || defaults.State
		this.ZipCode = options.ZipCode || defaults.ZipCode
		this.CustomerStatus = options.CustomerStatus || defaults.CustomerStatus
	}
}
