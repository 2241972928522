<template>
    <CContainer class="align-self-center v-container">
        <CRow class="justify-content-center">
            <CCol :xs="{ span: 12, offset: 0 }" :md="{ span: 12, offset: 0 }" :lg="{ span: 6, offset: 0 }" :xl="{ span: 4, offset: 0 }" class="shadow p-3 mb-5 bg-light-subtle border-start-secondary rounded border ">
                <CCard>
                    <CCardHeader class="text-center h5">Login</CCardHeader>
                    <CCardBody>
                        <CContainer class="px-1">
                            <CRow>
                                <CCol>
                                    <form @submit="onLoginClick">
                                        <DxForm
                                            ref="form"
                                            :form-data="formData"
                                            :show-validation-summary="true"
                                        >
                                            <DxItem
                                                data-field="Email"
                                                editor-type="dxTextBox"
                                                :validation-rules="validationRules.login"
                                                :editor-options="{ placeholder: 'Login (E-Mail)', width: '100%', height: 35 }"
                                            />
                                            <DxEmptyItem/>
                                            <DxItem
                                                data-field="Password"
                                                editor-type="dxTextBox"
                                                :validation-rules="validationRules.password"
                                                :editor-options="{ placeholder: 'Password', mode: 'password', width: '100%', height: 35 }"
                                            />
                                            <DxEmptyItem/>
                                            <DxButtonItem>
                                                <DxButtonOptions
                                                        width="100%"
                                                        type="default"
                                                        template="login"
                                                        :use-submit-behavior="true"
                                                        :height="35"
                                                >
                                                </DxButtonOptions>
                                            </DxButtonItem>

                                            <template #login>
                                                <div>
                                                    <span class="dx-button-text">
                                                        <span style="font-size: 1.5em">Login</span>
                                                    </span>
                                                </div>
                                            </template>
                                        </DxForm>
                                    </form>
                                </CCol>
                            </CRow>
                        </CContainer>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
/* eslint-disable */

import DxForm, {
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxCustomRule,
    DxRequiredRule,
    DxEmptyItem
} from 'devextreme-vue/form';

import {DxTextBox, DxButton as DxTextBoxButton} from 'devextreme-vue/text-box';
import {confirm} from "devextreme/ui/dialog";
import {CCol, CContainer, CRow} from "@coreui/vue/dist/esm/components/grid";
import {CCard, CCardBody, CCardHeader} from "@coreui/vue/dist/esm/components/card";

export default {
    name: 'login-view',

    components: {
        CCard,
        CCardBody,
        CCardHeader,
        CCol,
        CRow,
        CContainer,
        DxForm,
        DxItem,
        DxEmptyItem,
        DxLabel,
        DxButtonItem,
        DxButtonOptions,
        DxRequiredRule,
        DxCustomRule,
        DxTextBoxButton,
        DxTextBox
    },

    data() {
        return {
            error: null,
            formData: {
                Email: "",
                Password: "",
                DeviceName: "vue-app",
            },

            validating: false,

            validationRules: {
                login: [
                    { type: 'required', message: 'Login is required.' },
                ],
                password: [
                    { type: 'required', message: 'Password is required.' },
                ],
            }
        }
    },

    mounted() {
        this.formData.Email = this.User ? this.User.Email : ""
    },

    methods: {
        async onLoginClick(e) {
            e.preventDefault()

            await Promise.all([this.Login()])
        },

        async Login() {
            this.validating = true

            await this.$store.dispatch('user/login', this.formData)
                    .then((r) => {
                        this.validating = false

                        this.$gates.setPermissions(this.$store.getters["user/permissions"])
                        this.$gates.setRoles(this.$store.getters["user/roles"])
                        this.$root.$emit('user-login');

                        this.$router.push('/')
                    })
                    .catch(e => {
                        this.error = e
                        this.validating = false
                    }).finally(() => {
                        this.validating = false
                    })
        },

        async ClearCache() {
            let result = confirm("Are you sure?", `Clear Cache`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    localStorage.clear();
                    location.reload();
                }
            })
        }
    },

    computed: {
        AuthStatus() {
            return this.$store.getters["user/status"];
        },

        User() {
            return this.$store.getters['user/user']
        },

        PasswordTextBox() {
            return this.$refs.PasswordTextBoxRef.instance
        }
    }
}
</script>

<style>

.login-header {
    text-align: center;
    margin-bottom: 20px;
}
.login-header .title {
        color: blue;
        font-weight: bold;
        font-size: 20px;
        margin: 0;
    }

.login-header .amp {
    color: red;
    font-weight: bold;
    font-size: 24px;
    margin: 0;
}

.v-container {
    margin: auto;
    width: 65%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>
